import { useState, useContext, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import SEO from "../../components/seo";
import { GeoContext } from "../../context/GeoProvider";
import { convertPrice } from "../../context/helpers";
// temporary styles
import * as HelperStyles from "../temp_style/tools/_helpers.module.scss";
import * as Styles from "./terms.module.scss";
import { useCurrentCountryCode } from "../../hooks/usePrices";
import { getShopifyStoreByCountry } from "../../../locale-shopifies";

function Term({ title, tagline, details, isSelected, minAmount }) {
  const { gePriceDetails } = useContext(GeoContext);

  let _minAmount = minAmount;
  if (gePriceDetails && gePriceDetails.CountryCode !== "US") {
    _minAmount = `${gePriceDetails.CurrencySymbol}${convertPrice(_minAmount, gePriceDetails)}`;
  } else {
    _minAmount = `$${_minAmount}`;
  }

  let _details = details.replaceAll("_minamount_", _minAmount);
  _details = _details.replaceAll("\n", "<br />");

  return (
    <div className={`${Styles.terms__term} ${isSelected ? Styles.terms__term_selected : ""}`}>
      <h4 className="al_h4 al_h4--cap">
        <strong>{title}</strong> &nbsp;|&nbsp; {tagline}
      </h4>
      <p
        className={`${Styles.terms__detail} al_p`}
        dangerouslySetInnerHTML={{ __html: _details }}
      />
    </div>
  );
}

function TermsPage({ path }) {
  const countryCode = useCurrentCountryCode();

  const data = useStaticQuery(graphql`
    query {
      allContentfulPromoTermsPage(filter: { node_locale: { eq: "en-US" } }) {
        edges {
          node {
            terms {
              title
              tagline
              minAmount
              details {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (data) {
      const { terms } = data?.allContentfulPromoTermsPage?.edges?.[0]?.node;
      setContent(terms);
      setSelected(terms?.[0]?.title);
    }
  }, [data]);

  const [content, setContent] = useState([]);
  const [selected, setSelected] = useState(null);

  const handleChange = (e) => {
    setSelected(e.target.value);
  };

  if (content?.length && selected) {
    return (
      <>
        <SEO title="Promotional Terms & Conditions | Ana Luisa Jewelry" />
        <Helmet>
          <link rel="canonical" href={`https://www.analuisa.com${path}`} />
          <meta property="og:url" content={`https://www.analuisa.com${path}`} />
        </Helmet>
        <main className={Styles.terms}>
          <div className={HelperStyles.container}>
            <h1 className="al_h2">Promotional Terms & Conditions</h1>
            <div className={Styles.terms__sort}>
              <div className="select">
                <select name="followers" id="followers" title="Followers" onChange={handleChange}>
                  {content.map((term) => (
                    <option value={term.title} key={term.title}>
                      {term.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {content.map((term) => (
              <Term
                key={term?.title}
                title={term?.title}
                tagline={term?.tagline}
                details={term?.details?.childMarkdownRemark?.html}
                isSelected={term?.title === selected}
                minAmount={term?.minAmount}
              />
            ))}
            <h4 className=".al_h4--cap al_subtext">Need help?</h4>
            <p className={`${Styles.terms__links} al_subtext`}>
              Check out our{" "}
              <a
                href={getShopifyStoreByCountry(countryCode)?.knowledgeBase}
                target="_blank"
                rel="noreferrer"
              >
                FAQ page
              </a>{" "}
              or <a href="mailto:love@analuisa.com">email us</a>.
            </p>
          </div>
        </main>
      </>
    );
  }

  return null;
}

export default TermsPage;
